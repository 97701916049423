<template>
  <div class="servico">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2 mt-5">
          <!-- <lista v-if="$store.getters.getUser.profile == 3" :list.sync="list" /> -->
        </div>
        <div class="col-md-8 mt-5" id="frames">
         <installment/>
        </div> 
        <!-- //! ajuste de telas pinpad -->
        <div class="col-md-2 pr-0">
          <!-- <instrucao :instructions="instructions" :link="service.videoUrl" /> -->
        </div>
        <!-- <modalEdit :service="service" v-if="$store.getters.getUser.profile != 1" /> -->
      </div>
    </div>
  </div>
</template>
<script>
import installment from './installment.vue'

import lista from "@/components/servicos/interno/_ListaImpressora";

import instrucao from "@/components/servicos/interno/_Instrucao";
import modalEdit from "@/components/utils/solicitar_servicoEdit.vue";

export default {
  // props: ["service", "instructions", "categoryName", "score"],

  components: {
    lista,

    instrucao,
    modalEdit,

    installment
  },
  data() {
    return {
      totalPages: 0,
      list: [],
    };
  },
  watch: {
    list: function () {
      var total = this.list.reduce((total, item) => total + item.quantity, 0);
      this.totalPages = total;
    },
  },
  computed: {
    totalMetas() {
      return this.$store.getters.totalMetas;
    },
  },
  methods: {},
};
</script>

<style scoped>
.servico {
  background-color: #2474b2;
}
</style>
