<template>
  <div class="servico py-5">
    <div class="container-fluid">
      <div class="col-md-12">
        <div class="row">
          <div
            class="col-md-2"
            v-if="service.categoryId != 15 && service.categoryId != 9"
          >
            <lista
              v-if="$store.getters.getUser.profile == 3"
              :list.sync="list"
            />
          </div>
          <div
            :class="
              $store.getters.getUser.profile == 3 ? 'col-md-10' : 'col-md-12'
            "
          >
            <topo
              :service="service"
              :categoryName="categoryName"
              :score="score"
              :totalPages.sync="totalPages"
              :list.sync="list"
              :disabled.sync="disabled"
              @sendPedido="sendPedido()"
            />
            <div class="col-md-10">
              <div class="owl-carousel">
                <div
                  class="col-md-12"
                  style="margin-bottom: 20px"
                  v-for="(item, index) in instructions"
                  :key="index"
                >
                  <div
                    class="box d-flex flex-column align-items-center py-3"
                    :class="item.spotlight ? 'boxDestaque' : ''"
                  >
                    <div class="col-md-9 px-0 mx-auto imageBox">
                      <img :src="getImg(item.image)" @error="errorImage2" />
                    </div>
                    <div class="col-md-10 mx-auto img-box">
                      <h4 class="mb-0 text-center py-2">{{ item.title }}</h4>
                      <p class="mb-0">{{ item.description }}</p>
                    </div>
                    <div
                      class="position-absolute box_absoluto d-flex align-items-center justify-content-center"
                    >
                      <span>{{ item.sequence }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modalEdit
        :service="service"
        v-if="$store.getters.getUser.profile != 1"
      />
    </div>
  </div>
</template>

<script>
import topo from "@/components/servicos/externo/topo";
import modalEdit from "@/components/utils/solicitar_servicoEdit.vue";
import lista from "@/components/servicos/interno/_ListaImpressora";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    topo,
    lista,
    modalEdit
  },
  props: ["service", "instructions", "categoryName", "score"],
  data() {
    return {
      disabled: false,
      printInformationId: "",
      list: [],
      totalPages: 0,
      urlServices: "",
      jsonService: {},
      paramsCarousel: {
        loop: false,
        nav: true,
        dots: true,
        navText: [
          '<i class="fa fa-angle-left position-absolute icon_owl_left icon_owl" aria-hidden="true" ></i>',
          '<i class="fa fa-angle-right position-absolute  icon_owl_right icon_owl" aria-hidden="true" ></i>'
        ],
        items: 3
      }
    };
  },
  computed: {
    totalMetas() {
      return this.$store.getters.totalMetas;
    },
    ...mapGetters(["getOrderServiceId"])
  },
  mounted() {
    $(".owl-carousel").owlCarousel(this.paramsCarousel);
  },
  methods: {
    ...mapActions(["sendPedidos"]),
    ...mapMutations(["set_pedido"]),
    async sendPedido() {
      this.disabled = true;
      const jsonParams = {
        orderServiceId: this.getOrderServiceId,
        listPrint: this.list,
        quantity: ""
      };

      try {
        const result = await this.sendPedidos(jsonParams);
        this.set_pedido(result.data.result);
        this.$nextTick(() => {
          this.$router.push({ path: "/finalizar-servico" });
        });
        this.disabled = false;
      } catch (error) {
          this.disabled = false;
          if (error.response.data) {
            this.toast(error.response.data.error.message, this.$toast.error);
            this.$store.commit("changeOrderId", "");
            setTimeout(() => {
              this.$store.commit("set_pedido", "");
              this.$router.push("/pedidos");
            }, 2000);
            return
          }

          this.toast("Ops algo deu errado", this.$toast.error);
      }
    }
  },
  watch: {
    list: function() {
      let total = this.list.reduce((total, item) => total + item.quantity, 0);
      this.totalPages = total;
    }
  }
};
</script>

<style scoped>
.imageBox {
  height: 180px;
  overflow: hidden;
  border-radius: 20px;
}

.owl-dots {
  text-align: center;
}

.box_play:hover {
  color: #f38235;
  transition: all 0.2s linear;
}

.servico {
  background-color: #357eb8;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.box {
  background-color: #ffffff;
  min-height: 318px;
  border-radius: 6px;
}

.box p {
  font-size: 13px;
  text-align: center;
  color: #f38235;
  line-height: 1.2;
}

h4 {
  font-size: 14px;
  color: #2474b2;
  font-weight: 700;
}

.box_absoluto {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 40px;
  background-color: #357eb8;
  bottom: -20px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #ffffff;
}

.box_absoluto span {
  font-weight: 700;
  color: #ffffff;
}

.boxDestaque {
  background-color: #e0a638;
  transition: all 0.2s linear;
}

.boxDestaque p {
  color: #ffffff;
  transition: all 0.2s linear;
}

.boxDestaque h4 {
  color: #ffffff;
  transition: all 0.2s linear;
}

.boxDestaque .box_absoluto {
  background-color: #e0a638;
  transition: all 0.2s linear;
}
</style>
