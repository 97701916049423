<template>
  	<div class="col-md-10 py-3">
    	<div class="row align-items-center">
			<div class="borderBt">
				<div class="col-md-auto pl-0 pb-3">
					 <p class="mb-0 topo_p">
						 {{ categoryName }} / <strong>{{ service.name }}</strong>
					  </p>
				</div>
			</div>
			<div class="col-md-12 py-4 px-0 d-flex justify-content-between align-items-center containertop">
				<strong>{{ service.name }}</strong>
				<div>
					<h4>Valor:  <strong> {{  service.value | currency }} </strong></h4>
				</div>
			</div>
      	<div class="col-md-12 py-1 pr-1 d-flex justify-content-between align-items-center containerImp">
				<p class="m-0 col-4">Fila de impressão </p>
        	<div class="d-flex align-items-center justify-content-end col-4">
					<icons_custom icone="impressoraNew"
            bg="#868686"
            wi="25px"
            hei="25px"
            class="mr-1 icon"
						v-if="service.categoryId != 15"
					/>
					<!-- 9 copias de digitalização de produto -->
          <p class="mb-0 p_impressoes mr-3">
					<input 
            v-if="service.categoryId == 15 || service.categoryId == 9 || service.categoryId == 10" value="1" min="1" class="inputCopias input_default mx-2"
            type="number" 
            @input="$emit('update:list', [
              {
                quantity: parseFloat($event.target.value),
                file: '',
                linkForView: '',
                printInformationId: 0,
                status: 1
              }
            ])"
						/>
						<strong class="boxNumber" >{{totalPages > 9 ? totalPages : `0${totalPages}`}}</strong> {{ service.categoryId == 15 ? "Quantidade produtos" : "impressões" }}
						<!-- {{ service.categoryId == 15 ? "Quantidade produtos" : "impressões" }} -->
          	</p>
				</div>
        <i class="fas fa-play-circle mx-4 box_play" v-if="service.videoUrl" @click.prevent="showVideo()"></i>
				<div class="col-4 d-flex align-items-center justify-content-end pr-0">
					<button 
					  @click.prevent="$store.getters.getUser.profile == 3 ? $emit('sendPedido') : ''" 
					  class="btn_default py-2 d-flex align-items-center justify-content-center"
					  :disabled="disabled || getLoadService" 
					  :class="service.categoryId == 15 || service.categoryId == 9 || service.categoryId == 10 ? 'col-md-4' : 'col-md-6'"
				  >	
				  <half-circle-spinner
							 v-if="disabled || getLoadService"
							 :animation-duration="1000"
							 :size="30"
							 color="#fff"
						  />
						<p v-else class="my-2"> Próximo </p>
					</button>
				</div>
      </div>
    </div>
    <component
      :is="modalCp"
      v-if="service.videoUrl != ''"
      :link="service.videoUrl"
      @limpaIframe="limpaIframe"
    />
	 	<div class="col-md-2">
				<a style="color: white" v-if="$store.getters.getUser.profile != 1" href="#" class="link_a" @click.prevent="openModal()">
					Reportar problema no Serviço.
				</a>
			</div>
  </div>
</template>

<script>
import icons_custom from "@/components/utils/_Icon";
import modalIframe from "../_modalIframe";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters } from 'vuex';
export default {
  props: ["service", "categoryName", "score", "totalPages", "list", "disabled"],
  components: { modalIframe, icons_custom, HalfCircleSpinner },
  data() {
    return {
      modalCp: ""
    };
  },
  mounted() {
    if (
      this.service.categoryId == 15 ||
      this.service.categoryId == 9 ||
      this.service.categoryId == 10
    ) {
      this.$emit("update:list", [
        {
          quantity: parseFloat(1),
          file: "",
          linkForView: "",
          printInformationId: 0,
          status: 1
        }
      ]);
    }
  },
  computed: {
		...mapGetters([
			'getLoadService'
		])
	},
  methods: {
    showVideo() {
      this.modalCp = "modalIframe";
      this.$nextTick(() => {
        $("#modalIframe").modal("show");
      });
    },
    limpaIframe() {
      this.modalCp = "";
    },
    openModal() {
      $("#modalSolicitarServicoEdit").modal("show");
    }
  }
};
</script>

<style scoped>
.inputCopias {
  width: 80px;
  color: black !important;
}

.topo_p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
  color: #98C4E6;
}

.topo_p strong {
  color: #ffffff;
}

.containertop strong {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #FFFFFF;
}

.containertop h4 {
  color: #98C4E6;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

.containertop h4 strong {
  font-size: 20px;
}

.containerImp {
  background: #206aad;
  border-radius: 8px;
}
.containerImp p {
  font-size: 14px !important;
  color: #fff;
}

.boxNumber {
  background: #2474B2;
  border-radius: 6px;
  margin: 10px;
  padding: 11px 13px;
  font-weight: 600;
  font-size: 16px;
}
.borderBt {
  border-bottom: 1px solid #98c4e675;
  width: 100%;
}

.box_play {
  font-size: 18px;
  color: #ffff;
  transition: all 0.2s linear;
  cursor: pointer;
}

.btn_default {
  background-color: #f38235;
  border: none;
}

.btn_default[disabled] {
  opacity: 0.8;
}

.btn_default:hover {
  background-color: #e0a638;
}

.box_play:hover {
  color: #f38235;
  transition: all 0.2s linear;
}

.emoji img {
  width: 40px;
  margin-right: 10px;
  height: 40px;
}

.emoji p {
  font-size: 10px;
  color: #ffffff;
}

.emoji {
  opacity: 60%;
}

.icon {
  background-color: #fefefe;
  transition: all 0.2s linear;
}

.icon:hover {
  background-color: #e0a638;
  transition: all 0.2s linear;
}

.p_impressoes {
  color: #ffffff;
}
</style>
