<template>
  <div class="containerOnly col-6">
    <div class="containerInstallment">
      <div class="container">
        <div class="row">
          <h1>Tipo de Pessoa</h1>
          <div class="flex col-12 p-0 mt-2">
            <div class="form-check flex">
              <input
                class="form-check-input checkInput"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="CPF"
                v-model="check"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Física
              </label>
            </div>
            <div class="form-check flex ml-2">
              <input
                class="form-check-input checkInput"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="CNPJ"
                v-model="check"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Jurídica
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row flex-column">
          <div class="col-12 p-0">
            <div class="form-group mt-4 sizeContainer">
              <label for="InputCPF">{{ check }}: <b>*</b> </label>

              <the-mask
                v-if="check === 'CPF'"
                type="text"
                class="form-control"
                mask="###.###.###-##"
                v-model="CPF"
                placeholder="000.000.000-00"
              />
              <the-mask
                v-else
                type="text"
                class="form-control"
                mask="##.###.###/####-##"
                v-model="CNPJ"
                placeholder="00.000.000/0000-0"
              />
              <b class="textRequired" v-if="requerid">Campo Obrigatorio</b>
            </div>
          </div>
          <div class="col-12 p-0">
            <div class="form-group mt-4 sizeContainer">
              <label for="InputNumber">Telefone: <b>*</b></label>

              <the-mask
                type="text"
                class="form-control"
                :mask="['(##) ####-####', '(##) #####-####']"
                v-model="fone"
                placeholder="(xx) xxxxx-xxxx"
              />
              <b class="textRequired" v-if="requerid">Campo Obrigatorio</b>
            </div>
          </div>
          <div class="col-12 p-0 pr-0">
            <div class="form-group mt-4 sizeContainer">
              <label for="email">Email:</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="email"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- -->
      <div class="container">
        <div class="row">
          <div class="col-12 p-0 d-flex align-items-end">
            <!-- <button class="btn_default mr-4" @click="consultaapis(true)">
              Adicionar Conta
            </button> -->
            <button class="btn_default" @click="validetion">
              <loader color="#e0a638" size="20" v-if="load" />
              <h6 class="m-0" v-else>prosseguir</h6>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { API } from "@/api_financial";
import loader from "@/components/utils/_Load";
export default {
  components: { TheMask, loader },
  data() {
    return {
      check: "CPF",
      CPF: "",
      CNPJ: "",
      fone: null,
      email: null,
      requerid: false,
      load: false
    };
  },
  mounted() {
    this.getApiMounted();
  },
  methods: {
    validetion() {
      this.load = true;
      let { codigo } = this.$store.getters.usersDataValues; //!
      let { CPF, CNPJ, email, fone } = this;
      
      // if(this.$store.getters.codigoResponseData !== null) return

      if ((this.fone && this.CPF) || this.CNPJ) {
        API.post(
          "consultas",
          {
            codigoCliente: codigo,
            campos: [],
            debitos: [],
            contato: {
              cpf: CPF,
              cnpj: CNPJ,
              email: email,
              telefone: fone
            }
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getTokenZigNet}`,
              "Content-Type": "application/json"
            }
          }
        )
          .then(({ data }) => {
            let { codigo } = data;
            
            this.$store.commit("codigoResponseDataNewValue", codigo);

            // this.validationCod()
            this.consultaapis();

            this.componenteChangeStore("List");

            // $("#changeValues").modal("show");
            this.requerid = false;
            this.load = false;
          })
          .catch(err => {
            this.load = false;
            console.log(err);
          });
      } else {
        this.load = false;
        this.requerid = true;
      }
    },
    validationCod() {
      let codigo = this.$store.getters.codigoResponseData;

      API.get(`consultas/${codigo}/validar/${this.cod}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getTokenZigNet}`,
          "Content-Type": "application/json"
        }
      })
        .then(({ data }) => {
          //! dados pesoais

          console.log(data, "validar");

          return;
          this.$store.commit("dataContasNewValue", data);
        })
        .catch(err => {
          console.log("deu erro");
        });
    },
    toast(msn) {
      this.$toast(msn, {
        position: "bottom-right",
        timeout: 4000,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    consultaapis() {
      let { check, CPF, CNPJ, email, fone } = this;

      let jsonUser = {
        user: check,
        cpf: CPF,
        cnpj: CNPJ,
        fone,
        email
      };

      this.$store.commit("usersFinancialChangeValue", jsonUser);
    },
    getApiMounted() {
      API.get("clientes",{
        headers: {
          Authorization: `Bearer ${this.$store.getters.getTokenZigNet}`,
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          //! codigo da consulta ex: 31

          let data = res.data.clientes[3];
          this.$store.commit("usersDataChangeValue", data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.containerOnly {
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  border-radius: 11px;
  background: #357eb8;
  padding: 31px;
  margin-bottom: 30px;
}

input {
  height: 50px;
  background: #ededed;
}

.containerInstallment {
  color: #fff;
}
.containerInstallment h1 {
  font-size: 20px;
  margin: 100px 0 5px;
}

.flex {
  display: flex;
  align-items: center;
}

.checkInput {
  margin-top: 0 !important;
}

.sizeContainer {
  width: 100%;
}

.formBtn {
  display: flex;
  justify-content: flex-end;
}

.btn_default {
  background-color: #f38235;
  height: 50px;
  border: none;
  width: 150px;
  margin: 10px auto 5px 0px;
  padding: 10px;
}

.btn_default:hover {
  background-color: #e0a638;
}

label {
  cursor: pointer;
}

b {
  color: #f38235;
}
.textRequired {
  margin: 10px 0;
  font-size: 10px;
}
</style>
