var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-10 py-3"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"borderBt"},[_c('div',{staticClass:"col-md-auto pl-0 pb-3"},[_c('p',{staticClass:"mb-0 topo_p"},[_vm._v(" "+_vm._s(_vm.categoryName)+" / "),_c('strong',[_vm._v(_vm._s(_vm.service.name))])])])]),_c('div',{staticClass:"col-md-12 py-4 px-0 d-flex justify-content-between align-items-center containertop"},[_c('strong',[_vm._v(_vm._s(_vm.service.name))]),_c('div',[_c('h4',[_vm._v("Valor: "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.service.value))+" ")])])])]),_c('div',{staticClass:"col-md-12 py-1 pr-1 d-flex justify-content-between align-items-center containerImp"},[_c('p',{staticClass:"m-0 col-4"},[_vm._v("Fila de impressão ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-end col-4"},[(_vm.service.categoryId != 15)?_c('icons_custom',{staticClass:"mr-1 icon",attrs:{"icone":"impressoraNew","bg":"#868686","wi":"25px","hei":"25px"}}):_vm._e(),_c('p',{staticClass:"mb-0 p_impressoes mr-3"},[(_vm.service.categoryId == 15 || _vm.service.categoryId == 9 || _vm.service.categoryId == 10)?_c('input',{staticClass:"inputCopias input_default mx-2",attrs:{"value":"1","min":"1","type":"number"},on:{"input":function($event){_vm.$emit('update:list', [
              {
                quantity: parseFloat($event.target.value),
                file: '',
                linkForView: '',
                printInformationId: 0,
                status: 1
              }
            ])}}}):_vm._e(),_c('strong',{staticClass:"boxNumber"},[_vm._v(_vm._s(_vm.totalPages > 9 ? _vm.totalPages : ("0" + _vm.totalPages)))]),_vm._v(" "+_vm._s(_vm.service.categoryId == 15 ? "Quantidade produtos" : "impressões")+" ")])],1),(_vm.service.videoUrl)?_c('i',{staticClass:"fas fa-play-circle mx-4 box_play",on:{"click":function($event){$event.preventDefault();return _vm.showVideo()}}}):_vm._e(),_c('div',{staticClass:"col-4 d-flex align-items-center justify-content-end pr-0"},[_c('button',{staticClass:"btn_default py-2 d-flex align-items-center justify-content-center",class:_vm.service.categoryId == 15 || _vm.service.categoryId == 9 || _vm.service.categoryId == 10 ? 'col-md-4' : 'col-md-6',attrs:{"disabled":_vm.disabled || _vm.getLoadService},on:{"click":function($event){$event.preventDefault();_vm.$store.getters.getUser.profile == 3 ? _vm.$emit('sendPedido') : ''}}},[(_vm.disabled || _vm.getLoadService)?_c('half-circle-spinner',{attrs:{"animation-duration":1000,"size":30,"color":"#fff"}}):_c('p',{staticClass:"my-2"},[_vm._v(" Próximo ")])],1)])])]),(_vm.service.videoUrl != '')?_c(_vm.modalCp,{tag:"component",attrs:{"link":_vm.service.videoUrl},on:{"limpaIframe":_vm.limpaIframe}}):_vm._e(),_c('div',{staticClass:"col-md-2"},[(_vm.$store.getters.getUser.profile != 1)?_c('a',{staticClass:"link_a",staticStyle:{"color":"white"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal()}}},[_vm._v(" Reportar problema no Serviço. ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }