<template>
  <div
    :class="load ? 'servico d-flex align-items-center justify-content-center' : 'servico '">
    <half-circle-spinner
      :animation-duration="1000"
      :size="100"
      color="#fff"
      v-if="load"
    />
    <component
      :is="cp"
      :service="service"
      :instructions="instructions"
      :categoryName="categoryName"
      :score="score"
    />
  </div>
</template>

<script>
import systemic from "@/components/servicos/_sistemico";
import noSystemic from "@/components/servicos/_noSistemico";
import installment from "@/components/servicos/installment/_noSistema";
import { HalfCircleSpinner } from "epic-spinners";
import HTTP from "@/api_system";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    noSystemic,
    systemic,
    HalfCircleSpinner,
    installment
  },
  data() {
    return {
      load: true,
      cp: "",
      service: "",
      instructions: "",
      categoryName: "",
      score: ""
    };
  },
  computed: {
    ...mapGetters(["getOrderId", "getOrderServiceId"])
  },
  mounted() {
    let { id } = this.$route.params;
    const idPedido = this.$store.state.pedidos.pedidos.id;
    if (id === "Contas" || id === "Detran") {
      this.load = false;
      this.getInstallment();
      return;
    }

    this.serviceFinish()

    if(!idPedido) {
      this.getService();
    }
  },
  watch: {
    "$route.params.id": function(id) {
      if (id === "Contas" || id === "Detran") {
        this.load = false;
        this.getInstallment();
        return;
      }
      this.load = true;
      this.cp = "";
      this.getService();
    }
  },
  methods: {
     ...mapActions(["CheckFinishedOrder"]),
    ...mapMutations(["changeOrderId", "changeOrderServiceId", "changeLoadService"]),
    async serviceFinish () {
      const { id } = this.$store.state.pedidos.pedidos;

      if(!id) return

        this.getService();
      // try {
      //   const result = await this.CheckFinishedOrder(id)
      // } catch (error) {
      //   this.changeOrderId('')
      //   this.toast("O pedido solicitado já foi finalizado!", this.$toast.error);

      //   setTimeout(() => {
      //     this.$store.commit("set_pedido", "");
      //     this.$router.push("/pedidos");
      //   }, 2000);
      // }
    },
    async getService() {
      this.changeLoadService(true)
      try {
        const { data } = await HTTP.get(`services/app/Service/GetService?id=${this.$route.params.id}`, { headers: { Authorization: `bearer ${this.$store.getters.getterToken}`}})
        const { result } = data

        const id = result.service.id;
        this.getCreateService(id, result);


      } catch (error)  {
        console.error(error)
        this.toast('Ops algo deu errado', this.$toast.error)
      }
    },
    async getCreateService(serviceID, resultGetService) {
      // orderId é o id do pedido
      // orderServiceId é id do pedido item para eu fazer a proxima requisação
      // console.log(this.getOrderId, serviceID)
      try {
        const { data } = await HTTP.post( `services/app/Order/CreateOrEdit?serviceId=${serviceID}&orderId=${this.getOrderId}`, {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );

        this.service = resultGetService.service;
        this.instructions = resultGetService.instructions;
        this.categoryName = resultGetService.categoryName;
        this.score = resultGetService.score;

        if (this.service.type == 2) {
          this.cp = "noSystemic";
        } else {
          this.cp = "systemic";
        }
        this.load = false;


        const { orderId, orderServiceId } = data.result;
        this.changeOrderId(orderId);
        this.changeOrderServiceId(orderServiceId);
        this.changeLoadService(false)

      } catch (error) {
        console.error(error)
        this.toast(error.response.data.error.message, this.$toast.error)
        if(error.response.data.error.code === 302) {
          this.redirectPageError()
          return
        }

        if(error.response.data.error.code === 100) {
          this.redirectPageError()
          return
        }
      }
    },
     redirectPageError() {
      this.$store.commit("changeOrderId", "");
      setTimeout(() => {
        this.$store.commit("set_pedido", "");
        this.$router.push("/pedidos");
      }, 2000);
    },
    getInstallment() {
      this.cp = "installment";
    }
  }
};
</script>

<style scoped>
.servico {
  background-color: #2474b2;
}
</style>
