<template>
  <div
    class="modal fade"
    id="modalSolicitarServicoEdit"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-scrollable modal_custom"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-body px-4 py-4">
          <div>
            <ValidationObserver
              tag="form"
              ref="form"
              class="col-md-12 px-0"
              @submit.prevent="sendSolicity()"
            >
              <div class="col-md-12 mb-4 text-center">
                <p class="titulo_dash mb-0 d-flex align-items-center">
                  Solicitar edição serviço
                </p>
              </div>
              <!-- <div class="row align-items-center"> -->
              <ValidationProvider
                tag="div"
                class="col-md-12 px-0 mb-2"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="Serviço"
              >
                <label class="label_default" :class="classes">Serviço</label>
                <input
                  type="text"
                  class="input_default form-control"
                  :class="classes"
                  v-model="superObj.name"
                  @keypress="validarNome($event)"
                />
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider
                tag="div"
                class="col-md-12 px-0 mb-2"
                v-slot="{ errors, ariaMsg, classes }"
                rules="required"
                name="Categoria"
              >
                <label class="label_default" :class="classes">Categoria</label>
                <select
                  class="input_default form-control catSelect"
                  v-model="superObj.categoryId"
                  :class="classes"
                >
                  <option
                    v-for="(item, index) in categories"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- </div> -->
              <div class="col-md-12 my-3 px-0">
                <div class="row">
                  <ValidationProvider
                    tag="div"
                    class="col-md-6"
                    v-slot="{ errors, ariaMsg, classes }"
                    name="Valor"
                  >
                    <label class="label_default" :class="classes"
                      >Valor R$</label
                    >
                    <input
                      type="text"
                      class="input_default form-control"
                      v-model="superObj.value"
                      :class="classes"
                      @keypress="validarValor($event)"
                      @blur="decimalValue()"
                    />
                    <!-- @change="setValue($event.target.value)" -->
                    <!-- @input="$emit(`update:value`, parseFloat(valor))" -->
                    <span v-bind="ariaMsg" :class="classes">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    class="col-md-6"
                    v-slot="{ errors, ariaMsg, classes }"
                    name="Secundário"
                  >
                    <label class="label_default" :class="classes"
                      >Secundário R$</label
                    >
                    <input
                      type="text"
                      class="input_default form-control"
                      v-model="superObj.secondValue"
                      :class="classes"
                      @keypress="validarValor($event)"
                      @blur="decimalValue()"
                    />
                    <!-- @change="setSecundaryValue($event.target.value)" -->
                    <!-- @input="$emit(`update:secondValue`, parseFloat(secundario))" -->
                    <span v-bind="ariaMsg" :class="classes">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-12 my-3 px-0">
                <div class="row">
                  <validationProvider
                    class="col-md-12"
                    v-slot="{ errors, ariaMsg, classes }"
                    name="Link"
                    :rules="
                      superObj.type == 1
                        ? {
                            required: true,
                            regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                          }
                        : ''
                    "
                  >
                    <label
                      class="label_default"
                      :class="superObj.type == 2 ? '' : classes"
                      :style="
                        superObj.type == 2 ? 'color: #868686!important;' : ''
                      "
                      >Link</label
                    >
                    <input
                      type="text"
                      :disabled="superObj.type == 2 ? true : false"
                      class="input_default form-control"
                      :class="superObj.type == 1 ? classes : 'disabled'"
                      v-model="superObj.link"
                      @input="$emit('update:link', $event.target.value)"
                    />
                    <span v-bind="ariaMsg" :class="classes">{{
                      errors[0]
                    }}</span>
                  </validationProvider>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-3 ml-auto">
                  <button
                    class="btn_default d-flex justify-content-center align-items-center"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="disabled"
                    ></span>
                    <i class="fas fa-plus-circle mr-1" v-show="!disabled"></i>
                    {{ !disabled ? `Solicitar` : "" }}
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  props: ["service"],
  components: {},
  data() {
    return {
      disabled: false,
      categories: [],
      superObj: {
        serviceId: this.service.id,
        link: this.service.link,
        name: this.service.name,
        categoryId: this.service.categoryId,
        value: this.service.value,
        secondValue: this.service.secondValue,
        cost: null,
        statusService: 3,
        hasPopup: null,
        type: this.service.type
      }
    };
  },
  mounted() {
    this.loadCategories();
    this.decimalValue();
  },
  methods: {
    loadCategories() {
      this.$store.state.servicos.servicosCategoria.forEach(category => {
        this.categories.push({ id: category.id, name: category.name });
      });
    },
    sendSolicity() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.superObj.Value = this.superObj.value.replace(",", ".");
          this.superObj.SecondValue = this.superObj.secondValue.replace(
            ",",
            "."
          );

          HTTP.post(`services/app/ServiceEdit/CreateOrEdit`, this.superObj, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          })
            .then(response => {
              this.disabled = false;
              this.$notify({
                group: "erros",
                type: "sucess",
                text: `<i class="icon ion-close-circled"></i>Solicitação enviada`
              });
              $("#modalSolicitarServicoEdit").modal("hide");
            })
            .catch(error => {
              this.disabled = false;
              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>${error.response.data.error.message}`
              });
            });
        }
      });
    },
    validarNome(event) {
      if (
        event.charCode === 0 ||
        /^[A-Za-zÇç'áàÁÀéèÉÈíìÍÌóòÓÒùúãÃõÕâÂêÊôÔ ]/.test(
          String.fromCharCode(event.charCode)
        )
      ) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    validarValor(event) {
      if (
        event.charCode === 0 ||
        /[0-9]|\,/.test(String.fromCharCode(event.charCode))
      ) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    decimalValue() {
      this.superObj.value = this.becomeDecimal(this.superObj.value);
      this.superObj.secondValue = this.becomeDecimal(this.superObj.secondValue);
    },
    becomeDecimal(value) {
      if (value !== "") {
        if (value.toString().indexOf(".") == 1) {
          value = Number.parseFloat(value)
            .toFixed(2)
            .replace(".", ",");
        } else if (value.toString().indexOf(",") == 1) {
          value = value.replace(",", ".");
          value = Number.parseFloat(value)
            .toFixed(2)
            .replace(".", ",");
        } else {
          value = Number.parseFloat(value)
            .toFixed(2)
            .replace(".", ",");
        }
      } else {
        value = "0,00";
      }
      return value;
    }
    // setCategoryId() {
    //   this.$store.state.servicos.servicoscategorias.forEach(item => {
    //     if (item.name == $(".catSelect").val()) {
    //       this.service.categoryId = item.id;
    //     }
    //   });
    // }
    // ,setValue(value) {
    //   this.$emit(`update:value`, parseFloat(value.replace(",", ".")));
    // },
    // setSecundaryValue(value) {
    //   this.$emit(`update:secondValue`, parseFloat(value.replace(",", ".")));
    // }
  }
};
</script>

<style scoped>
.label_default {
  font-size: 14px;
  color: #2474b2 !important;
  font-weight: 500;
}
.input_flex {
  width: 65px;
}

.fas {
  font-size: 20px;
}
.btn_default {
  background-color: #e0a638;
  height: 40px;
  border: none;
}
.btn_default:hover {
  background-color: #f38235;
}
.btn_default_excluir {
  background-color: #e67979;
  height: 40px;
  border: none;
}

.modal_custom {
  max-width: 590px;
}

.modal-content {
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: #2474b2;
}

::-webkit-scrollbar {
  width: 5px;
  background: #2474b2;
}

::-webkit-scrollbar-thumb {
  background: #e0a638;
  border-radius: 10px;
}
.titulo_dash {
  color: #2474b2;
  font-weight: 700;
}
.input_default {
  height: 35px;
}
</style>
