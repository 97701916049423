<template>
  <div class="box_lista_impressora py-2">
    <div class="topo_box d-flex align-items-center px-3">
      <h4 class="mb-0">Fila de impressão</h4>
    </div>
    <div class="box_corpo">
      <transition-group name="list-complete" tag="div">
        <div
          class="col-md-12 box_arquivo py-1 list-complete-item"
          v-for="(item, index) in impress"
          :key="index + 1"
          :style="index == impress.length - 1 ? 'border-bottom:none;' : ''"
        >
          <div
            class="row align-items-center containerHei"
            :style="'color:' + item.color"
          >
            <div class="col-md-6">
              <p class="mb-0 elipse" :title="item.file">{{ `${item.file}` }}</p>
            </div>
            <div class="col-6 d-flex justify-content-around p-0">
              <div class="col-md-6 px-1 text-center">
                <p class="mb-0">{{ `${item.quantity}` }}</p>
              </div>
              <div>
                <label class="container">
                  <input type="checkbox" @click="update(item)" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <modalImage
            :index="index"
            :imagesPdf.sync="imagesPdf"
            ref="modalInit"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import modalImage from "./modalImage";
import { mapGetters } from "vuex";
export default {
  components: { modalImage },
  props: ["list"],
  data() {
    return {
      imagesPdf: [],
      impress: [],
      arr: []
    };
  },
  mounted() {
    this.getImpressao();
  },
  methods: {
    getImpressao() {
      HTTP.get(
        `services/app/PrintQueue/GetMyLastPrint`,

        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      ).then(response => {
        this.impress = response.data.result;
      });
    },
    showModal(index, linkForView) {
      $("#modalPdf" + index).modal("show");
      this.imagesPdf = "";
      var array = [];
      var arrayParse = JSON.parse(linkForView);

      arrayParse.forEach((element, i) => {
        fetch(`${element}`, {
          method: "GET",

          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(res => {
            return res.json();
          })
          .then(response => {
            this.imagesPdf = "";
            array.push(`data:image/jpeg;base64,${response.result}`);
            if (array.length == arrayParse.length) {
              if (response.result != "") {
                this.imagesPdf = array;
                this.$nextTick(() => {
                  this.$refs.modalInit[index].owlInit();
                });
              } else {
                this.$notify({
                  group: "erros",
                  type: "error",
                  text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
                });
                this.$nextTick(() => {
                  $("#modalPdf" + index).modal("hide");
                });
              }
            }
          });
      });
    },
    update(item) {
      var test = this.arr.findIndex(
        el => el.printInformationId == item.printInformationId
      );
      if (test == -1) {
        this.arr.push({
          file: item.file,
          printInformationId: item.printInformationId,
          quantity: item.quantity,
          linkForView: item.linkForView,
          status: 1
        });
      } else {
        this.arr.splice(test, 1);
      }
      this.$emit("update:list", this.arr);
    },
    GetColor(item) {
      var array = [];
      var obj = [];
      var color = ["#2474b2", "#f38235", "#35b890", "#E1A338", "#7510ce"];
      var colorNumber = 0;
      item.forEach(element => {
        if (!array.includes(element.computerId)) {
          var computerId = element.computerId;
          element.color = color[colorNumber];
          array.push(computerId);
          var item = {
            computerId: element.computerId,
            color: color[colorNumber]
          };
          obj.push(item);
          colorNumber++;
        } else {
          obj.forEach(element2 => {
            if (element2.computerId == element.computerId)
              element.color = element2.color;
          });
        }
      });
      return item;
    }
  },
  computed: {
    ...mapGetters(["get_message"])
  },
  watch: {
    get_message: function() {
      if (
        this.get_message == `4_${this.$store.getters.tenantRequire.tenantId}`
      ) {
        this.getImpressao();
      }
    }
  }
};
</script>

<style scoped>
.elipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box_corpo {
  max-height: 140px;
  overflow: auto;
  overflow-x: hidden;
}

.topo_box {
  height: 40px;
}
.box_arquivo {
  height: auto;
  /* display: flex;
  align-items: center; */
  border-bottom: 1px solid#E3E3E3;
}
.box_arquivo {
  color: #175d93;
}

.box_lista_impressora {
  border-radius: 6px;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  background-color: #ffffff;
}
h4 {
  font-weight: 700;
  font-size: 14px;
  color: #2474b2;
}
::-webkit-scrollbar-track {
  background-color: #2474b2;
}

::-webkit-scrollbar {
  width: 2px;
  background: #2474b2;
}

::-webkit-scrollbar-thumb {
  background: #f38235;
  border-radius: 10px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #25d366;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.containerHei {
  height: 30px;
}
</style>
