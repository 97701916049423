<template>
  <div>
    <h1>boleto / {{ $route.params.id === 'Contas' ? 'Parcelamento de Contas' : 'Parcelamento Débitos Detran' }}</h1>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

<style scoped>
h1 {
  color: #fff;
  font-size: 14px;
}
</style>